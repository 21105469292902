<template lang="pug">
  FormModal.modal-new-collection(title="New Collection", endpoint="collections", :data="data", v-bind="$attrs", @success="onSuccess")
    InputGroup(label="Privacy", name="type", v-if="user.hasPermission('collections.shared_manage') && user.hasPermission('collections.manage')")
      Radios(v-model="data.type", inline)
        Radio(value="partner", @change="onTypeChange()")
          Icon(icon="users")
          div
            div: strong Shared Collection
            div.option-label Shared among all members of your team.
        Radio(value="personal", @change="onTypeChange()")
          Icon(icon="user")
          div
            div: strong Personal Collection
            div.option-label Private and visible only to you.

    InputGroup(label="Name", name="name")
      TextInput(v-model="data.name", prepend-icon="collection", textarea, :rows="1", auto-focus)

    InputGroup(label="Folder", name="folder_id")
      Select(v-model="data.folder_id", :endpoint="`options/${data.type}/folders`", option-label="label", prepend-option="No Folder", prepend-icon="folder", prepend-icon-prefix="fas")

    InputGroup(label="Language", name="language_id")
      Select(v-model="data.language_id", endpoint="options/languages", prepend-icon="language", prepend-icon-prefix="fas")

    InputGroup(label="Internal code — optional", name="code")
      TagsInput(v-model="data.code", prepend-icon="tag")

    AdminOnly
      InputGroup(label="Markets — optional", name="partner_market_ids", description="Press 'enter' after each market. Apply a specific market to this content experience. Note: Leaving this blank makes it accessible to all users everywhere without restriction.")
        MultiSelect(v-model="data.partner_market_ids", placeholder="Enter market...", endpoint="search/partner-markets", searchable)

    PublishAtInput(v-model="data.inspires_publish_at", label="Make public on inspires - optional", description="Choose a date to make this content experience “public,” meaning available to all users on inspires.to. NB: Do not set a date if this content experience is “private,” meaning available to only a select group of users.")
    ExpiresAtInput(v-model="data.expires_at", description="Choose a date to remove this content experience from the view of all users on inspires.to. NB: This expiration will also apply to “private” content experiences.")
</template>

<script>
import { mapGetters } from "vuex"
import ExpiresAtInput from "@/components/Form/ExpiresAtInput.vue"
import PublishAtInput from "@/components/Form/PublishAtInput.vue"

export default {
  components: {
    ExpiresAtInput,
    PublishAtInput
  },
  props: {
    type: {
      type: String,
      default: null
    },
    folder_id: {
      type: Number,
      default: null
    }
  },
  data: () => ({
    data: {
      type: null,
      name: "",
      folder_id: null,
      language_id: 1,
      code: [],
      partner_market_ids: [],
      inspires_publish_at: null,
      expires_at: ""
    }
  }),
  computed: {
    ...mapGetters({
      user: "auth/user"
    })
  },
  created() {
    if (this.type === "partner" && this.user.hasPermission("collections.shared_manage")) {
      this.data.type = "partner"
    } else if (this.type === "personal" && this.user.hasPermission("collections.manage")) {
      this.data.type = "personal"
    } else if (this.user.hasPermission("collections.shared_manage") && this.user.hasPermission("collections.manage")) {
      this.data.type = "partner"
    } else if (this.user.hasPermission("collections.shared_manage")) {
      this.data.type = "partner"
    } else if (this.user.hasPermission("collections.manage")) {
      this.data.type = "personal"
    }

    this.data.folder_id = this.folder_id
  },
  methods: {
    onTypeChange() {
      this.data.folder_id = null
    },
    onSuccess(result) {
      this.$notification.success(`Collection **${this.data.name}** successfully created.`)
      this.$emit("close", result)
    }
  }
}
</script>

<style>
.modal-new-collection,
.modal-edit-collection,
.modal-duplicate-collection,
.modal-new-folder,
.modal-edit-folder {
  .form-check-group {
    @apply space-x-3;
  }

  .form-check {
    @apply w-1/2;

    &-custom-input {
      @apply hidden;
    }

    &.is-checked .form-label {
      @apply border-primary-default;
    }
  }

  .form-label {
    @apply block rounded border-2 border-solid py-6 pl-6 pr-8;
  }

  .form-check-content {
    @apply ml-0 flex;

    .icon {
      @apply mr-3 mt-2 w-12 text-2xl text-supplement;
    }
  }

  .option-label {
    @apply text-supplement;
  }
}
</style>
